<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>{{objectName}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            :to="`/form/settings/${activeObject}`"
        >
          <v-icon color="red">mdi-amplifier</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >

        <v-btn
          class="mr-1"
          outlined
          v-if="docRights && docRights.write &&  withPayment==0"
          color="toolbarIcon"
          text
          :to="`/inventory/transactions/create/${activeObject}`"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New
        </v-btn>
        <v-btn
          class="mr-1"
          outlined
          v-if="docRights && docRights.write &&  withPayment==1"
          color="toolbarIcon"
          text
          :to="`/sales/doc/create/${activeObject}?withPayment=1`"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New
        </v-btn>
        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-btn
            icon
            class="ml-10"
            right
            @click="setFilter"
          >
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="3">
          <v-autocomplete
            outlined
            dense
            v-model="DocFilter"
            :items="docTypes"
            item-text="name"
            item-value="value"
            label="Filter Documents"
            @change="setFilter"
          ></v-autocomplete>
        </v-col>
      </v-toolbar>
      <!-- end of toolbar 2 -->
      <v-card-text>
        <v-row dense>

          <v-col cols="12">
            <v-col cols="3">
              <v-autocomplete
                dense
                outlined
                v-model="created_by"
                :items="allUsersData"
                label="Created By"
                item-text="name"
                @change="setFilter"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
              single-line
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-data-table
              dense
              v-model="selectedDocs"
              :items-per-page="itemsPerPage"
              v-if="docRights.read == true"
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="docData"
              :search="search"
            >
              <!-- start of posting date template -->
              <template v-slot:item.DocDate="{ item }">{{ item.DocDate | moment("Do MMM YYYY")}}</template>
              <!-- end of posting date template -->

              <!-- action template -->
              <template v-slot:item.DocNum="{ item }">
                <v-btn
                  class="btnLink"
                  color="flatButton"
                  text
                  :to="`/inventory/transactions/view/${activeObject}/${item.id}?isDoc=${item.isDoc}`"
                >{{ item.DocNum }}</v-btn>
              </template>

              <template v-slot:item.syncStatus="{ item }">
                <v-btn
                  @click="openerrorDocDialog(item)"
                  color="red"
                  icon
                  v-show="item.withErrors == true"
                >
                  <v-icon>mdi-alert</v-icon>
                </v-btn>
                <v-btn
                  color="green"
                  icon
                  v-show="item.withErrors == false"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>

                <v-btn
                  color="grey"
                  icon
                  v-show="item.withErrors == 'upload'"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.action="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.actionType"
                  :items="item.DocStatus == 'O'  ? availableActionsOpen : availableActionsClosed"
                  item-text="ActionName"
                  item-value="value"
                  @change="processAction(item)"
                  style="width: 100px;"
                ></v-autocomplete>
              </template>
            </v-data-table>

            <v-alert
              type="
                  error"
              v-if="docRights.read == false"
            >You are not authorised to read data</v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- snackbar -->

    <!-- category quick add dialog -->
    <v-dialog
      v-model="closeDocDialog"
      max-width="650px"
    >
      <v-card>
        <v-toolbar
          dense
          color="red"
          dark
        >
          <v-toolbar-title>Close Document</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.native="closeDocDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                Closing cannot be reversed,Continue?.
              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn
                    color="green"
                    dark
                    @click="closeDocument()"
                  >Yes</v-btn>
                </v-col>

                <v-col cols="6">
                  <v-btn
                    color="red"
                    dark
                    @click="closeDocDialog = false"
                  >No</v-btn>
                </v-col>
              </v-row>
            </v-row>

          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

    <!-- category quick add dialog -->
    <v-dialog
      v-model="errorDocDialog"
      max-width="650px"
    >
      <v-card>
        <v-toolbar
          dense
          color="red"
          dark
        >
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.native="errorDocDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:

              </v-col>
              <v-col cols="12">

                <span>
                  {{this.ErrorMessage}}
                </span>

              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn
                    color="green"
                    dark
                    @click="errorDocDialog = false"
                  >Close</v-btn>
                </v-col>
              </v-row>
            </v-row>

          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

      <!-- Approvers Dialog -->
      <v-dialog
              v-model="approversDialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
      >
          <v-card>
              <v-toolbar
                      dark
                      color="accent"
              >
                  <v-toolbar-title>
                      <v-btn
                              icon
                              dark
                              @click="approversDialog = false"
                      >
                          <v-icon dark>mdi-keyboard-backspace</v-icon>
                      </v-btn>Approver Status
                  </v-toolbar-title>
                  <v-spacer></v-spacer>

              </v-toolbar>

              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col cols="12">
                              <v-data-table
                                      :items-per-page="itemsPerPage"
                                      :headers="approverHeaders"
                                      :items="approversDetails"
                                      item-key="id"
                              >
                                  <template v-slot:item.ApprovalTime="{ item }">{{ item.ApprovalTime | moment("LLLL")}}</template>
                              </v-data-table>
                          </v-col>
                      </v-row>

                  </v-container>
              </v-card-text>
          </v-card>
      </v-dialog>
      <!-- end of modal-->

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    objectName: null,
    objectCode: null,
    createUrl: null,
    withPayment: null,
    //itemsPerPage: 100,
    docData: [],
    selectedDocs: [],
    activeObject: null,
    singleSelect: true,
    loading: false,
    docRights: {},
    dateItem: {},
    actionType: null,
    closeDocDialog: false,
    errorDocDialog: false,
    ErrorMessage: null,
    DocFilter: 1,
    approversDetails: [],
    approversDialog: false,
    approverHeaders: [
      { text: "Name", value: "userDetails.name" },
      { text: "Status", value: "StatusComment" },
      { text: "Time", value: "ApprovalTime" },
      { text: "Remarks", value: "StatusRemarks" },
    ],
    // availableActionsClosed: [{ ActionName: "View", value: "View" }],
    // availableActionsOpen: [
    //   { ActionName: "View", value: "View" },
    //   { ActionName: "Close", value: "Close" },
    // ],
    docTypes: [
      { name: "Documents", value: 1 },
      { name: "Drafts", value: 0 },
      { name: "SAP Open Documents", value: 2 },
    ],
    created_by: null,
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 10,
      page: 1,
    },
    totalItems: 0,
  }),
  watch: {
    "$route.params.objectId": {
      handler: "getDocs",
      immediate: true,
    },
  },
  mounted: function () {},
  computed: {
    allUsersData() {
      return this.$store.state.userdata;
    },
    headers() {
      return [
        { text: "", value: "syncStatus" },
        { text: "Doc No.", value: "DocNum" },
        { text: "SAP #", value: "ExtRefDocNum" },
        { text: "Posting Date", value: "DocDate" },
        { text: "Created By", value: "created_by.name" },
        { text: "Action", value: "action" },
      ];
    },
    availableActionsOpen() {
      if (this.DocFilter == 0) {
          return [
              { ActionName: "View", value: "View" },
              { ActionName: "Close", value: "Close" },
              { ActionName: "Approval", value: "Approval" },
          ];
      }
      return [
          { ActionName: "View", value: "View" },
          { ActionName: "Close", value: "Close" },
      ];
    },
    availableActionsClosed() {
      return [{ ActionName: "View", value: "View" }];
    },
  },
  methods: {
    getDocs(val) {
      const self = this;
      self.docData = [];
      if (val) {
        // check permission
        this.$store
          .dispatch("get", `/authorization/check-if-permitted/${val}`)
          .then((res) => {
            self.docRights = res;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
        // end of permission check

        this.activeObject = val;

        //const url = `/inventory-transactions-data/${val}?isDoc=${this.DocFilter}`;
        const url = `/inventory-transactions-data/${val}?isDoc=${this.DocFilter}&page=${this.options.page}&per_page=${this.options.itemsPerPage}`;

        const data = {};
        data.created_by = this.created_by;
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            if (res.ResultCode == 1200) {
              self.docData = res.ResponseData;
              self.options.page = res.ResponseData.current_page;
              self.options.itemsPerPage = res.ResponseData.per_page;
              self.totalItems = res.ResponseData.total;
            }
            self.loading = false;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });

        this.$store
          .dispatch("get", `/doc_model/${val}`)
          .then((res) => {
            self.objectName = res.DocumentName;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
        // end of doc title

        if (this.$route.query.withPayment == undefined) {
          this.withPayment = 0;
        }
        if (this.$route.query.withPayment == 1) {
          this.withPayment = 1;
        }
      }
    },
    openCloseDocumentDialog(item) {
      if (this.DocFilter != 1) {
        return;
      }
      this.dateItem = item;
      this.closeDocDialog = true;
    },

    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },

    processAction(item) {
      const self = this;
      const pos = this.docData.indexOf(item);
      if (pos === -1) {
        return;
      }
      this.docData[pos] = item;

      if (item.actionType == "Close" && item.DocStatus == "O") {
        this.openCloseDocumentDialog(item);
        return;
      }

      if (item.actionType == "Errors" && item.DocStatus == "E") {
        this.openerrorDocDialog(item);
        return;
      }

      if (item.actionType == "View") {
        this.$router.push(
          `/sales/doc/${this.activeObject}/${item.id}?isDoc=${this.DocFilter}`
        );
      }

        if (item.actionType == "Approval" && item.DocStatus == "O") {
            this.loading = true;
            const url = `/marketing-doc-approvers/${this.activeObject}/${item.id}`;
            const data = {};
            data.created_by = this.created_by;
            this.$store
                .dispatch("post", { url, data })
                .then((res) => {
                    if (res.ResultCode == 1200) {
                        self.approversDetails = res.ResponseData.approvers;
                        if (res.ResponseData) {
                            this.approversDialog = true;
                        }
                    }
                    self.loading = false;
                })
                .catch((err) => {
                    this.$refs.snackbar.show(err, "red");
                });
            return;
        }
    },
    setFilter() {
      this.getDocs(this.activeObject);
    },
  },
  created() {
    this.getDocs();
  },
};
</script>